import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/box",
    name: "box",
    component: () => import("@/views/box/index.vue")
  },
  // {
  //   path: "/intro",
  //   name: "intro",
  //   component: () => import("@/views/intro/index.vue"),
  // },
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/customer/index.vue")
  },
  {
    path: "/station",
    name: "station",
    component: () => import("@/views/station/index.vue")
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("@/views/operation/index.vue")
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("@/views/promotion/index.vue")
  },
  {
    path: '/placement',
    name: 'placement',
    component: () => import("@/views/placement/index.vue")
  }
  // {
  //   path: "/demo",
  //   name: "demo",
  //   component: () => import("@/views/home/index.vue")
  // }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
