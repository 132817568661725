import Vue from "vue"
import App from "@/App.vue"
import router from "@/router"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import locale from "element-ui/lib/locale/lang/zh-CN"
import { PiniaVuePlugin } from "pinia"
// import vueCompositionApi from '@vue/composition-api'
import pinia from "@/store"

Vue.config.productionTip = false

Vue.use(ElementUI, { locale })
Vue.use(PiniaVuePlugin)

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app")
