<template>
  <div id="app" class="app">
    <Header />
    <router-view />
    <Footer />
    <account-dialog ref="loginRef" />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, Provide } from "vue-property-decorator"
import AccountDialog from "@/components/account/index.vue"
import { ACCOUNT_STATE } from "./utils/enums"

@Component({
  components: {
    Header: () => import("./components/header/index.vue"),
    Footer: () => import("./components/footer/index.vue"),
    AccountDialog,
  },
})
export default class App extends Vue {
  @Ref() readonly loginRef!: AccountDialog

  @Provide()
  showAccount = (state: ACCOUNT_STATE) => {
    const loginRef = this.$refs.loginRef as AccountDialog
    loginRef.open(state)
  }

  @Provide()
  hideAccount = () => {
    const loginRef = this.$refs.loginRef as AccountDialog
    loginRef.close()
  }
}
</script>

<style lang="css">
body {
  margin: 0;
}

/* #app {
  background-image: url(@/assets/images/bg.png);
  background-repeat: no-repeat;
  background-size: 1000% 100%;
} */
</style>
